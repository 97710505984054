.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.btn-outline {
  border: 1px solid #1c9d87;
  background-color: white;
  border-radius: 5px;
  color: #1c9d87;
}

.w-100 {
  width: 100%;
}

.btn-medium {
  padding: 1rem;
}

.align-items-center {
  align-items: center;
}

.btn-primary,
.btn-outline,
.btn-secondary,
.btn-load-more {
  padding: 0.8rem 1.5rem;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  font-weight: 600;
}

.btn-secondary {
  background: #f8a492;
  border: 1px solid #f8a492;
  border-radius: 32px;
  color: white;
}

.rounded {
  border-radius: 25px !important;
  cursor: pointer;
}