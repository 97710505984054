@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800;1,900&display=swap");

* {
  font-family: "Rubik", sans-serif;
  font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  font-weight: normal;

  p {
    line-height: 1.4rem;
    letter-spacing: 0.5px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-weight: 400;
}

main {
  position: relative;
  margin-top: 50px;
}


.container {
  max-width: 95%;
  margin-inline: auto;

  @media (min-width: 992px) {
    max-width: 85%;
  }

  @media (min-width: 1200px) {
    max-width: 80%;
  }
}


.text-center {
  text-align: center;
}


.header {
  margin-block: 4rem;
  span {
    font-weight: 300;
    @media screen and (max-width: 600px) {
      display: block;
    }
  }
  @media screen and (max-width: 600px) {
    margin-top: 1rem;
  }
}

.home-page {
  background-color: #f5faf9;
}

.grid-view {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;

  @media (min-width: 992px) and (max-width: 1400px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (min-width: 768px) and (max-width: 991.99px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 767.99px) {
    grid-template-columns: 1fr;
  }

  &.cards-view {
    @media (max-width: 600px) {
      gap: 0.6rem;
      grid-template-columns: 1fr 1fr;
    }
  }
}

.heading {
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Rubik;
  letter-spacing: 0px;
  color: #44554C;
  opacity: 0.7;
  display: flex;
  justify-content: center;
}

.sub-heading {
  margin-top: 20px;
  text-align: left;
  font-size: 18px;
  font-family: Rubik;
  font-weight: 500;
  letter-spacing: 0px;
  color: #142C1F;
  opacity: 1;
  display: flex;
  justify-content: center;
  @media (max-width: 600px) {
    text-align: center;
  }
}

.load-more-action {
  margin-top: 2rem;
}

.pagination-nav {
  .pagination {
    display: inline-block;
    list-style: none;
    padding: 0;
    li {
      display: inline-block;
      padding: 0.8rem;

      span {
        padding: 0;
        font-size: 1.2rem;
        text-decoration: none;
        color: #000000;
        cursor: pointer;
        &:hover,
        &:active {
          color: #1c9d87;
        }

        &.active-page {
          color: #1c9d87;
          font-weight: 700;
        }
      }
      svg {
        color: #1c9d87;
        cursor: pointer;
        width: 2rem;
        height: 2rem;
        position: relative;
        top: 0.7rem;
      }
    }
  }
}


