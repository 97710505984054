
.dropdown {
  position: relative;
  width: fit-content;
  .dropdown-top,
  .dropdown-menu {
    padding: 1rem;
  }

  .dropdown-top {
    width: fit-content;
    gap: 0.4rem;
    cursor: pointer;

    .title {
      font-size: 1.3rem;
    }
    .icon {
      svg {
        position: relative;
        top: 0.15rem;
      }
    }
    &.open {
      box-shadow: 1px -3px 12px #9c9a9a47;
      background-color: white;
    }

    @media screen and (max-width: 600px) {
      .title {
        font-size: 1rem;
      }
      .icon {
        svg {
          top: 0.15rem;
        }
      }
    }
  }

  .dropdown-menu {
    background-color: white;
    max-width: 400px;
    z-index: 999;
    width: 400px;
    box-shadow: 1px 4px 5px 0px #b0aeae4d;
    gap: 1rem;
    position: absolute;
    top: 3.5rem;
    left: 0;

    @media screen and (max-width: 600px) {
      max-width: 100%;
    }
  }
}